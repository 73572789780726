@font-face {
    font-family: 'helveticalight';
    src: url('../public/assets/fonts/helvetica_light_regular-webfont.eot');
    src: url('../public/assets/fonts/helvetica_light_regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../public/assets/fonts/helvetica_light_regular-webfont.woff2') format('woff2'),
        url('../public/assets/fonts/helvetica_light_regular-webfont.woff') format('woff'),
        url('../public/assets/fonts/helvetica_light_regular-webfont.ttf') format('truetype'),
        url('../public/assets/fonts/helvetica_light_regular-webfont.svg#helveticalight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'helveticabold';
    src: url('../public/assets/fonts/helvetica-bold-webfont.eot');
    src: url('../public/assets/fonts/helvetica-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../public/assets/fonts/helvetica-bold-webfont.woff2') format('woff2'),
        url('../public/assets/fonts/helvetica-bold-webfont.woff') format('woff'),
        url('../public/assets/fonts/helvetica-bold-webfont.ttf') format('truetype'),
        url('../public/assets/fonts/helvetica-bold-webfont.svg#helveticabold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
//  font-family: "Inter", sans-serif;


:root {
    --white: #ffffff;
    --color98A2B3: #98A2B3;
    --colorE4E7EC: #E4E7EC;
    --colorF2F4F7: #F2F4F7;
    --color0C111D: #0C111D;
    --color344054: #344054;
    --color1B1B1B: #1B1B1B;
}

body {
    font-size: 16px;
    line-height: 1.4;
    color: var(--white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    padding: 0;
    font-family: 'helveticalight';
    overflow-x: hidden;
}

*{
    box-sizing: border-box;
}
a {
    display: inline-block;
}

.body_content {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    z-index: 1;

    .body_background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: #0F1013;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .body_children {
        padding: 36px 0;

    }
}


.MuiContainer-root {
    padding: 0 18px !important;
}

// input field global style
.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    display: none;
}

.MuiInputBase-root {
    border: 1px solid var(--color98A2B3);
    border-radius: 0 !important;

    &.Mui-focused {
        border: 1px solid var(--white);
        box-shadow: 0px 2px 7.8px 0px rgba(255, 255, 255, 0.502);

    }

    .MuiInputBase-input {
        padding: 12px 12px;
        color: var(--white);
        font-size: 14px;

        &::-webkit-input-placeholder {
            color: var(--color98A2B3);
            opacity: 1;
        }

        &::-moz-placeholder {
            color: var(--color98A2B3);
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: var(--color98A2B3);
            opacity: 1;
        }

        &:-moz-placeholder {
            color: var(--color98A2B3);
            opacity: 1;
        }
    }
}



// common button style
a.cmnBtn{
    width: 100%;
    background-color: var(--colorF2F4F7);
    border: 1px solid var(--colorF2F4F7);
    border-radius: 0;
    color: var(--color0C111D);
    font-family: 'helveticabold';
    text-align: center;
    text-decoration: none;
    padding: 8px 10px;
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: transparent;
        color: var(--colorF2F4F7);
    }
}
.MuiButtonBase-root {
    &.cmnBtn {
        width: 100%;
        background-color: var(--colorF2F4F7);
        border: 1px solid var(--colorF2F4F7);
        border-radius: 0;
        color: var(--color0C111D);
        font-family: 'helveticabold';
        min-height: 40px;
        padding: 5px 8px;

        &:hover {
            background-color: transparent;
            color: var(--colorF2F4F7);
        }

        &.Mui-disabled{
            background: var(--color1B1B1B);
            border-color: var(--color1B1B1B);
            color: var(--color98A2B3);

            &.disbaledWhite{
                background-color: var(--colorF2F4F7);
                border: 1px solid var(--colorF2F4F7);
                color: var(--color0C111D);
            }
        }
    }

}

// vertically centered section
.verficallyCenteredSection {
    min-height: calc(100vh - 192px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 120px;
}